<script setup lang="ts">
const tweetId = ref<string | null>(null);
const forCampaignUuid = ref<string | null>(null);
const forCampaignWeekId = ref<string | null>(null);

function openModal(event: {
  tweetId: string | null;
  forCampaignUuid: string | null;
  forCampaignWeekId: string | null;
}) {
  tweetId.value = event.tweetId;
  forCampaignUuid.value = event.forCampaignUuid;
  forCampaignWeekId.value = event.forCampaignWeekId;
}

onMounted(() => {
  globalOn("tweetPosted", openModal);
});

onBeforeUnmount(() => {
  globalOff("tweetPosted", openModal);
});
</script>

<template>
  <Sidebar v-if="tweetId" @close="tweetId = null">
    <SidebarsTweetDetailSidebar
      :tweetId="tweetId"
      :forCampaignUuid="forCampaignUuid"
      :forCampaignWeekId="forCampaignWeekId"
    />
  </Sidebar>
</template>
