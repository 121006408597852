<script setup lang="ts">
const { $trpc } = useNuxtApp();

defineProps<{
  name: string;
  email?: string;
  handle?: string;
}>();

async function stopSpoofing() {
  const redirectUrl = await $trpc.stopSpoofing.mutate();
  clearTokens();
  window.location.href = redirectUrl;
}
</script>

<template>
  <div
    class="fixed top-12 left-1/2 transform -translate-x-1/2 z-50 p-2 px-4 max-w-full md:max-w-3xl bg-red bg-opacity-50 rounded-xl"
  >
    <div class="flex items-center justify-between gap-4 text-sm">
      <span>Spoofing:</span>
      <span>{{ name }}</span>
      <span v-if="handle"> @{{ handle }} </span>
      <span v-if="email"> {{ email }} </span>
      <button class="px-3 py-1.5 text-sm" @click="stopSpoofing">Stop</button>
    </div>
  </div>
</template>
