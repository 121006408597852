<script setup lang="ts">
import EvangelistIcon from "../Icons/EvangelistIcon.vue";
import type { Brand } from "~/advertisorsData/shared/types";
import { getBrand } from "~/advertisorsData/shared/getBrand";
import { CreatorappCampaignWeekUserEligibilityState as EligibilityState } from "evangelist-db-schema/api";
import { useQuery } from "@tanstack/vue-query";

const emit = defineEmits(["closeAndRefresh"]);

interface Props {
  campaignWeekId: string;
  seeCriteria?: boolean;
}
const props = defineProps<Props>();

const { $trpc } = useNuxtApp();
const {
  data: campaign,
  isLoading: isCampaignLoading,
  isSuccess: isCampaignLoaded,
  promise,
} = useQuery({
  queryKey: ["getCampaign", props.campaignWeekId],
  queryFn: () =>
    $trpc.getCampaign.query({ campaignWeekId: props.campaignWeekId }),
});
const { data: wallets } = useQuery({
  queryKey: ["listCampaignWeekWallets", props.campaignWeekId],
  queryFn: () =>
    $trpc.listCampaignWeekWallets.query({
      campaignWeekId: props.campaignWeekId,
    }),
});

const brand = ref<Brand>();

const hasAdditionalWalletChecks = computed(() => {
  return (campaign.value?.apyBonus ?? 0) > 0;
});

const lastNonEligibleWalletAddress = computed(() => {
  if (!hasAdditionalWalletChecks.value) {
    return null;
  }
  if (!wallets.value) {
    return null;
  }
  for (const wallet of wallets.value) {
    if (!wallet.isEligible) {
      return wallet.address;
    }
  }
  return null;
});

const eligibilityRestrictions = computed(() => {
  if (!campaign.value) {
    return [];
  }

  const restrictions: string[] = [];
  if (campaign.value.restrictionAccountAgeDays) {
    if (campaign.value.restrictionAccountAgeDays === 365) {
      restrictions.push(`Account age is more than one year`);
    } else {
      restrictions.push(
        `Account age is more than ${campaign.value.restrictionAccountAgeDays} days`,
      );
    }
  }
  if (campaign.value.restrictionMinFollowers) {
    restrictions.push(
      `Has more than ${campaign.value.restrictionMinFollowers} followers`,
    );
  }

  return restrictions;
});

function onWalletSaved() {
  globalEmit("walletSaved", props.campaignWeekId);
}

watch(
  () => [isCampaignLoaded.value, campaign.value] as const,
  ([isCampaignLoaded, campaign]) => {
    if (isCampaignLoaded && campaign) {
      getBrand(campaign.advertiser.theme).then((b) => (brand.value = b));
    }
  },
  { immediate: true },
);
</script>

<template>
  <LoadingIcon v-if="isCampaignLoading || !campaign" />
  <div v-else>
    <div class="text-lg mb-6 pr-10 lg:pr-0">
      <p class="text-gray-400">Creator eligibility criteria for:</p>
      <p>{{ campaign.title }}</p>
    </div>

    <div v-if="campaign.eligibilityState === EligibilityState.Ineligible">
      <div v-if="hasAdditionalWalletChecks && seeCriteria === false">
        <div class="text-black/50 flex items-center gap-3 flex-wrap">
          <BrandsIconAndName :theme="campaign?.advertiser?.theme" />
          <span class="w-1 h-1 rounded-full bg-black/50"></span>
          <CampaignDateInfo :campaign="campaign" />
          <span
            v-if="campaign.signupBonus > 0"
            class="w-1 h-1 rounded-full bg-black/50"
          ></span>
          <div
            v-if="campaign.apyBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-xs bg-[#CFCBFF] text-black"
          >
            <IconsShieldIcon class="mr-[4px] text-black fill-black" />
            Verified Members
          </div>
          <div
            v-if="campaign.signupBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-black text-xs bg-gray-300"
          >
            {{ campaign.signupBonus }} {{ campaign.budgetCurrency }} Sign up
            Bonus
          </div>
        </div>

        <div v-if="brand" class="mt-8">
          <p
            v-for="(criteriaDescription, i) of brand.eligibilityDescriptions[
              'not-eligible'
            ]"
            :key="i"
            :class="[i === 0 ? '' : 'mt-4']"
          >
            {{ criteriaDescription }}
          </p>
        </div>
        <p v-if="lastNonEligibleWalletAddress" class="mt-6 text-[#6659FF]">
          Wallet {{ lastNonEligibleWalletAddress }} you tried verifying with is
          not eligible, please try again.
        </p>
      </div>
      <div v-if="!hasAdditionalWalletChecks || seeCriteria">
        To join this campaign, advertiser set following criteria for creators to
        be eligible:

        <ul class="list-disc px-5 mt-6">
          <li
            v-for="(restriction, restrictionIndex) in eligibilityRestrictions"
            :key="restrictionIndex"
          >
            {{ restriction }}
          </li>
        </ul>
      </div>

      <a
        v-if="!hasAdditionalWalletChecks || seeCriteria"
        href="mailto: support@evangelist.gg"
        class="inline-block bg-[#CFCBFF] rounded-full px-[15px] py-[8px] mt-6"
      >
        Contact support
      </a>

      <WalletsWalletConnector
        v-if="hasAdditionalWalletChecks && seeCriteria === false"
        class="flex gap-2 bg-[#CFCBFF] rounded-full px-[15px] py-[8px] mt-8"
        :campaign="campaign"
        @saved="onWalletSaved"
      >
        {{
          lastNonEligibleWalletAddress ? "Try another wallet" : "Connect Wallet"
        }}
      </WalletsWalletConnector>
    </div>

    <div
      v-if="campaign.eligibilityState === EligibilityState.Pending"
      class="text-md"
    >
      <div class="flex flax-wrap">
        <div class="text-black/50 flex items-center gap-3 flex-wrap">
          <BrandsIconAndName :theme="campaign?.advertiser?.theme" />
          <span class="w-1 h-1 rounded-full bg-black/50"></span>
          <CampaignDateInfo :campaign />
          <span
            v-if="campaign.signupBonus > 0"
            class="w-1 h-1 rounded-full bg-black/50"
          ></span>
          <div
            v-if="campaign.apyBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-xs bg-[#CFCBFF] text-black"
          >
            <IconsShieldIcon class="mr-[4px] text-black fill-black" />
            Verified Members
          </div>
          <div
            v-if="campaign.signupBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-black text-xs bg-gray-300"
          >
            {{ campaign.signupBonus }} {{ campaign.budgetCurrency }} Sign up
            Bonus
          </div>
        </div>
      </div>

      <div v-if="brand" class="mt-6">
        <p
          v-for="(criteriaDescription, i) of brand.eligibilityDescriptions
            .evaluating"
          :key="i"
          :class="[i === 0 ? '' : 'mt-4']"
        >
          {{ criteriaDescription }}
        </p>
      </div>

      <div class="flex gap-4 items-center mt-6">
        <button class="flex gap-2 bg-gray-200 rounded-full px-[15px] py-[12px]">
          <EvangelistIcon class="h-[20px] w-[20px] animate-spin" />
          <span>Verifying...</span>
        </button>

        <WalletsWalletConnector
          class="text-gray-400"
          :campaign="campaign"
          @saved="onWalletSaved"
        >
          Try another wallet
        </WalletsWalletConnector>
      </div>
    </div>
  </div>
</template>
