<script setup lang="ts">
const campaignWeekId = ref<string>();
const seeCriteria = ref(false);

function handleSidebar(event: {
  campaignWeekIdToOpen: string | undefined;
  seeCriteria: boolean;
}) {
  seeCriteria.value = event.seeCriteria;
  campaignWeekId.value = event.campaignWeekIdToOpen;
}

onMounted(() => {
  globalOn("campaignForSidebarClicked", handleSidebar);
});

onBeforeUnmount(() => {
  globalOff("campaignForSidebarClicked", handleSidebar);
});
</script>

<template>
  <Sidebar v-if="campaignWeekId" @close="campaignWeekId = undefined">
    <CampaignSidebar
      :campaignWeekId="campaignWeekId"
      :seeCriteria="seeCriteria"
    />
  </Sidebar>
</template>
