<script setup lang="ts">
const opened = ref(false);
const withBonus = ref(false);

const store = useDefaultStore();
const campaignEligibleNotifications = computed(
  () => store.campaignEligibleNotifications,
);
const route = useRoute();

const routeCampaignId = computed(() => {
  return route.params.id as string;
});

const campaignEligibleNotification = computed(() => {
  return campaignEligibleNotifications.value.find(
    (notification) =>
      notification.callToActionParam?.campaignId === routeCampaignId.value,
  );
});

watch(
  campaignEligibleNotification,
  () => {
    if (campaignEligibleNotification.value) {
      openModal({
        withBonus:
          campaignEligibleNotification.value?.callToActionParam?.apyBonus > 0,
      });

      setNotificationAsSeen(campaignEligibleNotification.value.id);
    }
  },
  {
    immediate: true,
  },
);

function setNotificationAsSeen(notificationId: string) {
  apiService.markAsSeen(notificationId).then(() => {
    store.refreshNotifications();
  });
}

function openModal(opt: { withBonus?: boolean }) {
  opened.value = true;
  withBonus.value = opt.withBonus || false;
}

function close() {
  opened.value = false;
}
</script>

<template>
  <LazyModalsEligibilityPassedModal
    v-if="opened"
    :with-bonus="withBonus"
    @close="close"
  />
</template>
